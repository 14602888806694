import Http from '@/tog/services/http';
import ErrorHandler from '@/tog/services/error-handler';
import { getNonFieldErrors } from '@/tog/store/utils';

const PASSWORD_RETRIEVAL_REQUEST_URL = '/api/v1/auth/password/retrieval/';
const PASSWORD_RETRIEVAL_RESET_URL = '/api/v1/auth/password/retrieval/';

const state = {
  email: '',
  data: {},
  errors: {}
};

const getters = {
  getPasswordRetrievalEmail(state) {
    return state.email;
  },
  getPasswordRetrievalData(state) {
    return state.data;
  },
  getPasswordRetrievalErrors(state) {
    return state.errors;
  },
  getPasswordRetrievalNonFieldErrors(state) {
    return getNonFieldErrors(state.errors);
  }
};

const mutations = {
  setPasswordRetrievalEmail(state, email) {
    state.email = email;
    state.errors = {};
  },
  setPasswordRetrievalData(state, data) {
    state.data = { ...data };
    state.errors = {};
  },
  setPasswordRetrievalDataProp(state, { prop, value }) {
    state.data = { ...state.data, [prop]: value };
    state.errors = { ...state.errors, [prop]: null };
  },
  setPasswordRetrievalErrors(state, errors) {
    state.errors = { ...errors };
  }
};

const actions = {
  async sendPasswordRetrievalEmail({ commit, state, getters }) {
    try {
      const http = new Http({ auth: false });
      await http.fetch('post', PASSWORD_RETRIEVAL_REQUEST_URL, { data: { email: state.email } });
      commit('setPasswordRetrievalEmail', '');
      return true;
    } catch (err) {
      commit('setPasswordRetrievalErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getPasswordRetrievalNonFieldErrors);
      errorHandler.handle();
      return false;
    }
  },
  async sendPasswordRetrievalData({ commit, state, getters }) {
    try {
      const http = new Http();
      await http.fetch('put', PASSWORD_RETRIEVAL_RESET_URL, { data: state.data });
      commit('setPasswordRetrievalData', {});
      return true;
    } catch (err) {
      commit('setPasswordRetrievalErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getPasswordRetrievalNonFieldErrors);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
