export const ITEMS_PER_PAGE = 10;
export const ITEMS_PER_PAGE_OPTIONS = [5, 10, 25, 100];

export const DATE_OPTIONS = {
  timeZone: 'CET',
  dateStyle: 'long'
};

export const DATE_TIME_OPTIONS = {
  timeZone: 'CET',
  dateStyle: 'medium',
  timeStyle: 'short'
};
