export default {
  mainPage: 'Strona główna',

  email: 'E-mail',
  password: 'Hasło',
  password1: 'Hasło',
  password2: 'Potwierdź hasło',
  passwordReset: 'Resetuj hasło',
  logIn: 'Zaloguj się',
  logOut: 'Wyloguj się',
  myData: 'Moje dane',
  myAccount: 'Moje konto',
  contactDataUpdate: 'Zmień dane kontaktowe',
  passwordUpdate: 'Zmień hasło',
  oldPassword: 'Obecne hasło',
  newPassword1: 'Nowe hasło',
  newPassword2: 'Potwierdź nowe hasło',
  basicParameters: 'Parametry podstawowe',
  programPoints: 'Punkty programu',
  contactData: 'Dane kontaktowe',
  request: 'Zapytanie',
  requestNew: 'Nowe zapytanie',
  requestSummary: 'Podsumowanie zapytania',
  requestNo: 'Zapytanie nr: {number}',
  requestArchive: 'Archiwizuj zapytanie',
  requestUnarchive: 'Cofnij archiwizację',
  requests: 'Zapytania',
  requestsSelf: 'Moje zapytania',
  requestsLoadNext: 'Wczytaj kolejne zapytania',
  noRequests: 'Brak zapytań',
  finished: 'Gotowe',
  destination: 'Kierunek wyjazdu',
  destinationChoose: 'Wybierz kierunek',
  destinationNew: 'Nowy kierunek',
  destinations: 'Kierunki',
  destinationsLoadNext: 'Wczytaj kolejne kierunki',
  numberOfStudents: 'Liczba uczniów',
  ageOfStudents: 'Wiek uczniów',
  numberOfTeachers: 'Liczba opiekunów',
  numberOfDays: 'Czas trwania',
  departureDates: 'Termin wyjazdu',
  airport: 'Lotnisko wylotu/przylotu',
  food: 'Rodzaj wyżywienia',
  departureDateFrom: 'Termin wyjazdu, od',
  departureDateTo: 'Termin wyjazdu, do',
  school: 'Szkoła (nazwa i miejscowość)',
  legalName: 'Imię i nazwisko',
  phone: 'Telefon',
  remarksAndComments: 'Uwagi i komentarze',
  giodoAgreement: 'Zgoda GIODO',
  days: '0 dni | {n} dzień | {n} dni | {n} dni',
  user: 'Użytkownik',
  userNew: 'Nowy użytkownik',
  userType: 'Rodzaj użytkownika',
  users: 'Użytkownicy',
  usersActive: 'Aktywni',
  usersInactive: 'Nieaktywni',
  attraction: 'Atrakcja',
  attractionNew: 'Nowa atrakcja',
  attractionTypes: 'Rodzaje atrakcji',
  attractions: 'Atrakcje',
  noAttractions: 'Brak atrakcji.',
  amount: 'Kwota',
  suggestedAttraction: 'Atrakcja sugerowana',
  complementaryAttraction: 'Atrakcja uzupełniająca',
  historicalAttraction: 'Atrakcja historyczna',
  naturalAttraction: 'Atrakcja przyrodnicza',
  additionalFee: 'Opłata dodatkowa',
  status: 'Status',
  draftNo: 'Wersje robocze ({n})',
  sentNo: 'Wysłane ({n})',
  archiveNo: 'Archiwum ({n})',
  firstVersionPreview: 'Podgląd pierwszej wersji',
  lastVersionPreview: 'Podgląd ostatniej wersji',
  searchDots: 'Szukaj...',
  isActive: 'Czy aktywny',
  actions: 'Akcje',
  refresh: 'Odśwież',
  image: 'Obraz',
  imagePreviewDot: 'Podgląd.',
  order: 'Kolejność',
  description: 'Opis',
  multimedia: 'Multimedia',
  multimediaNew: 'Nowe multimedia',
  multimediaType: 'Rodzaj zasobu',
  noMultimedia: 'Brak zasobów',
  ytLink: 'Adres URL do serwisu YouTube',
  comment: 'Komentarz',
  commentAdd: 'Dodaj komentarz',
  comments: 'Komentarze',
  commentsNo: 'Komentarze ({n})',
  commentsLoadNext: 'Wczytaj kolejne komentarze',
  noComments: 'Brak komentarzy',
  typeComment: 'Napisz komentarz',
  shareRequest: 'Udostępnij zapytanie',
  shareTourLink: 'Adres do tego zapytania',
  linkCopiedDot: 'Adres został skopiowany.',
  register: 'Zarejestruj się',
  registrationWasSuccessful: 'Rejestracja przebiegła pomyślnie.',
  sentEmailWithActivationLink: (
    'Na podany adres e-mail została wysłana wiadomość z linkiem aktywującym konto.'),
  passwordResetWasSuccessful: 'Hasło do portalu zostało zresetowane.',
  sentEmailWithResetLink: (
    'Na adres e-mail została wysłana wiadomość z linkiem pozwalającym nadać nowe hasło.'),
  filter: 'Filtruj',
  filters: 'Filtry',
  or: 'lub',
  accountProvider: 'Rodzaj konta',
  localAccount: 'Konto lokalne',
  name: 'Nazwa',
  systemManagement: 'Zarządzanie systemem',
  file: 'Plik',
  fileNew: 'Nowy plik',
  fileType: 'Rodzaj pliku',
  filePreviewDot: 'Podgląd pliku.',
  files: 'Pliki',
  noFiles: 'Brak plików',
  url: 'Adres URL',
  termsOfService: 'Regulamin serwisu',
  privacyPolicy: 'Polityka prywatności',
  privacyPolicyAccusative: 'Politykę prywatności',
  planTour: 'Zaplanuj wycieczkę',
  details: 'Szczegóły',

  next: 'Dalej',
  save: 'Zapisz',
  delete: 'Usuń',
  saveForm: 'Zapisz formularz',
  cancel: 'Anuluj',
  ok: 'Ok',
  send: 'Wyślij',
  return: 'Wróć',
  edit: 'Edycja',
  close: 'Zamknij',
  clear: 'Wyczyść',
  apply: 'Zastosuj',
  copy: 'Kopiuj',
  yes: 'Tak',
  no: 'Nie',
  requiredField: 'Pole wymagane.',
  removeQuestion: 'Usunąć?',
  removeElementQuestion: 'Czy usunąć wybrany element?',
  invalidFormat: 'Niepoprawny format.',
  registeredDot: 'Stworzono nowe konto.',
  forgotPasswordQuestion: 'Nie pamiętasz hasła?',
  passwordResetQuestion: 'Czy zresetować hasło?',

  savedDot: 'Zapisano.',
  formSentDot: 'Formularz został wysłany.',
  saveErrorDot: 'Wystąpił błąd przy próbie zapisu.',
  fetchErrorDot: 'Wystąpił błąd przy próbie pobrania danych.',
  deleteErrorDot: 'Wystąpił błąd przy próbie usunięcia danych.',
  requestArchivedDot: 'Zapytanie zostało zarchiwizowane.',
  requestUnarchivedDot: 'Zapytanie zostało wycofane z archiwum.',

  generalErrorDot: 'Wystąpił błąd.',
  unauthorizedErrorDot: 'Brak danych uwierzytelniających.',
  forbiddenErrorDot: 'Brak uprawnień.',
  notFoundErrorDot: 'Nie znaleziono zasobu.',
  methodNotAllowedErrorDot: 'Niepoprawne żądanie.',
  timeoutErrorDot: 'Przekroczono czas oczekiwania.',
  internalServerErrorDot: 'Wystąpił błąd serwera.',
  unexpectedErrorDot: 'Wystąpił niezidentyfikowany błąd.',
  sessionExpiredDot: 'Sesja wygasła.',
  authStateErrorDot: (
    'Wystąpił błąd przy utrzymaniu stanu autoryzacji. Odśwież stronę i spróbuj ponownie.'),

  yourFormHasBeenSent: 'Twój formularz został wysłany',
  toEditItGoToMyRequests: 'aby go edytować przejdź do zakładki {requestsSelf}.',
  toTypeACommentLogIn: 'Aby napisać komentarz, {logIn}.',
  loggedInAs: 'Zalogowany jako {email}',
  notYou: 'Nie ty? {logout}.',
  youHaveAccount: 'Masz już konto? {logIn}.',
  youDontHaveAccount: 'Nie masz jeszcze konta? {register}.',
  yourAccountHasBeenActivated: 'Twoje konto zostało aktywowane. {logIn}.',
  byClickingIAccept: 'Klikając "{action}", akceptuję {doc1} oraz {doc2}.',
  pageDoesNotExists: 'Strona nie istnieje lub została zarchiwizowana.'
};
