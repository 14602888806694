import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const MEDIAS_URL = '/api/v1/tours/attraction-medias/:id/';
const MEDIAS_ARRAY_URL = '/api/v1/tours/attraction-medias/array/';
const MEDIA_TYPES_URL = '/api/v1/tours/attraction-medias/types/';

const state = {
  medias: [],
  mediasServerLen: 0,
  mediasArray: [],
  mediasLoading: false,
  media: {},
  mediaErrors: {},
  mediaLoading: false,
  types: []
};

const getters = {
  getAttractionMedias(state) {
    return state.medias;
  },
  getAttractionMediasServerLen(state) {
    return state.mediasServerLen;
  },
  getAttractionMediasArray(state) {
    return state.mediasArray;
  },
  isAttractionMediasLoading(state) {
    return state.mediasLoading;
  },
  getAttractionMedia(state) {
    return state.media;
  },
  getAttractionMediaErrors(state) {
    return state.mediaErrors;
  },
  getAttractionMediaNonFieldErrors(state) {
    return getNonFieldErrors(state.mediaErrors);
  },
  isAttractionMediaLoading(state) {
    return state.mediaLoading;
  },
  getAttractionMediaTypes(state) {
    return state.types;
  }
};

const mutations = {
  setAttractionMedias(state, { medias, serverLen = null }) {
    state.medias = [...medias];
    state.mediasServerLen = serverLen || medias.length;
  },
  appendAttractionMedias(state, { medias, serverLen = null }) {
    state.medias = [...state.medias, ...medias];
    state.mediasServerLen = serverLen || medias.length;
  },
  updateAttractionMedias(state, media) {
    const oldLen = state.medias.length;
    state.medias = assignIf(state.medias, m => m.id === media.id, media);
    state.mediasServerLen += state.medias.length - oldLen;
  },
  deleteAttractionMedias(state, media) {
    const oldLen = state.medias.length;
    state.medias = deleteIf(state.medias, m => m.id === media.id);
    state.mediasServerLen += state.medias.length - oldLen;
  },
  setAttractionMediasArray(state, medias) {
    state.mediasArray = [...medias];
  },
  setAttractionMediasLoading(state, loading) {
    state.mediasLoading = loading;
  },
  setAttractionMedia(state, media) {
    state.media = { ...media };
    state.mediaErrors = {};
  },
  setAttractionMediaProp(state, { prop, value }) {
    state.media = { ...state.media, [prop]: value };
    state.mediaErrors = { ...state.mediaErrors, [prop]: null };
  },
  setAttractionMediaErrors(state, errors) {
    state.mediaErrors = { ...errors };
  },
  setAttractionMediaLoading(state, loading) {
    state.mediaLoading = loading;
  },
  setAttractionMediaTypes(state, types) {
    state.types = [...types];
  }
};

const actions = {
  async fetchAttractionMedias({ commit }, { params = {}, append = false } = {}) {
    try {
      commit('setAttractionMediasLoading', true);
      const rest = new Rest();
      const url = new Url(MEDIAS_URL, { id: null });
      const response = await rest.get(url.toString(), params);
      const mutator = append ? 'appendAttractionMedias' : 'setAttractionMedias';
      commit(mutator, {
        medias: response.data.results,
        serverLen: response.data.count
      });
      return true;
    } catch (err) {
      commit('setAttractionMedias', { medias: [], serverLen: 0 });
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionMediasLoading', false);
    }
  },
  async fetchAttractionMediasArray({ commit }, { params = {} } = {}) {
    try {
      const rest = new Rest();
      const response = await rest.get(MEDIAS_ARRAY_URL, params);
      commit('setAttractionMediasArray', response.data);
      return true;
    } catch (err) {
      commit('setAttractionMediasArray', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  },
  async fetchAttractionMedia({ commit }, { id, params = {} }) {
    try {
      commit('setAttractionMediaLoading', true);
      const rest = new Rest();
      const url = new Url(MEDIAS_URL, { id });
      const response = await rest.get(url.toString(), params);
      commit('setAttractionMedia', response.data);
      return true;
    } catch (err) {
      commit('setAttractionMedia', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionMediaLoading', false);
    }
  },
  async saveAttractionMedia({ state, getters, commit }) {
    try {
      commit('setAttractionMediaLoading', true);
      const rest = new Rest();
      const url = new Url(MEDIAS_URL, { id: state.media.id });
      const save = state.media.id ? rest.patch : rest.post;
      let response;
      if (state.media.image instanceof Blob) {
        const form = new FormData();
        for (const prop in state.media) {
          if (state.media[prop] !== null && state.media[prop] !== undefined) {
            form.append(prop, state.media[prop]);
          }
        }
        response = await save.call(
          rest, url.toString(), form, { headers: { 'Content-Type': 'multipart/form' } });
      } else if (state.media.image === null) {
        response = await save.call(rest, url.toString(), state.media);
      } else {
        // eslint-disable-next-line no-unused-vars
        const { image, ...data } = state.media;
        response = await save.call(rest, url.toString(), data);
      }
      commit('setAttractionMedia', response.data);
      commit('updateAttractionMedias', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setAttractionMediaErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getAttractionMediaNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionMediaLoading', false);
    }
  },
  async deleteAttractionMedia({ commit }, media) {
    try {
      commit('setAttractionMediaLoading', true);
      const rest = new Rest();
      const url = new Url(MEDIAS_URL, { id: media.id });
      await rest.delete(url.toString());
      commit('deleteAttractionMedias', media);
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.deleteErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionMediaLoading', false);
    }
  },
  async fetchAttractionMediaTypes({ commit }, { params = {} } = {}) {
    try {
      const rest = new Rest();
      const response = await rest.get(MEDIA_TYPES_URL, params);
      commit('setAttractionMediaTypes', response.data);
      return true;
    } catch (err) {
      commit('setAttractionMediaTypes', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
