import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import ErrorHandler from '@/tog/services/error-handler';
import { getNonFieldErrors } from '@/tog/store/utils';

const PASSWORD_URL = '/api/v1/users/self/password/';

const state = {
  password: {},
  passwordErrors: {},
  passwordLoading: false
};

const getters = {
  getPassword(state) {
    return state.password;
  },
  getPasswordErrors(state) {
    return state.passwordErrors;
  },
  getPasswordNonFieldErrors(state) {
    return getNonFieldErrors(state.passwordErrors);
  },
  isPasswordLoading(state) {
    return state.passwordLoading;
  }
};

const mutations = {
  setPassword(state, password) {
    state.password = { ...password };
    state.passwordErrors = {};
  },
  setPasswordProp(state, { prop, value }) {
    state.password = { ...state.password, [prop]: value };
    state.passwordErrors = { ...state.passwordErrors, [prop]: null };
  },
  setPasswordErrors(state, errors) {
    state.passwordErrors = { ...errors };
  },
  setPasswordLoading(state, loading) {
    state.passwordLoading = loading;
  }
};

const actions = {
  async savePassword({ state, getters, commit }) {
    try {
      commit('setPasswordLoading', true);
      const rest = new Rest();
      await rest.patch(PASSWORD_URL, state.password);
      commit('setPassword', {});
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setPasswordErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getPasswordNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setPasswordLoading', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
