import { render, staticRenderFns } from "./check-icon.vue?vue&type=template&id=4efeafc5&scoped=true&functional=true&"
var script = {}
import style0 from "./check-icon.vue?vue&type=style&index=0&id=4efeafc5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4efeafc5",
  null
  
)

export default component.exports