const state = {
  step: 1
};

const getters = {
  getStep(state) {
    return state.step;
  }
};

const mutations = {
  setStep(state, step) {
    state.step = step;
  },
  nextStep(state) {
    if (state.step < 5) {
      state.step++;
    }
  },
  prevStep(state) {
    if (state.step > 1) {
      state.step--;
    }
  }
};

export default {
  state,
  getters,
  mutations
};
