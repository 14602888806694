import { isAuthorized } from '@/tog/router/utils';

export default [{
  path: '/profile',
  name: 'profile:update',
  beforeEnter: isAuthorized,
  component: () => import(/* webpackChunkName: 'tours' */ '@/users/views/profile-update')
}, {
  path: '/users',
  name: 'users:list',
  beforeEnter: isAuthorized,
  component: () => import(/* webpackChunkName: 'tours' */ '@/users/views/user-list')
}];
