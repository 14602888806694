class Url {
  constructor(pattern, params = {}) {
    this.pattern = pattern;
    this.params = params;
  }

  toString() {
    let repr = this.pattern;
    for (const param in this.params) {
      if (this.params[param] !== null && this.params[param] !== undefined) {
        repr = repr.replace(`:${param}`, this.params[param]);
      }
    }
    return repr.replace(/:\w+?\//g, '');
  }
}

export default Url;
