import stepper from '@/tours/store/stepper';
import destinations from '@/tours/store/destinations';
import tours from '@/tours/store/tours';
import attractions from '@/tours/store/attractions';
import attractionMedias from '@/tours/store/attraction-medias';

export default {
  namespaced: true,
  modules: {
    stepper,
    destinations,
    tours,
    attractions,
    attractionMedias
  }
};
