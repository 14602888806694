const state = {
  active: false,
  indeterminate: false
};

const getters = {
  isActive(state) {
    return state.active;
  },
  isIndetermine(state) {
    return state.indeterminate;
  }
};

const mutations = {
  setIndetermine(state) {
    state.active = true;
    state.indeterminate = true;
  },
  unsetIndetermine(state) {
    state.active = false;
    state.indeterminate = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
