import auth from '@/auth/store/auth';
import perms from '@/auth/store/perms';
import registration from '@/auth/store/registration';
import password from '@/auth/store/password';

export default {
  namespaced: true,
  modules: {
    auth,
    perms,
    registration,
    password
  }
};
