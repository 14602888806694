import i18n from '@/plugins/i18n';
import Http from '@/tog/services/http';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { getNonFieldErrors } from '@/tog/store/utils';

const REGISTRATION_URL = '/api/v1/auth/registration/';
const REGISTRATION_CONFIRMATION_URL = '/api/v1/auth/registration/confirmation/:token/:userId/';

const state = {
  registration: {},
  registrationErrors: {}
};

const getters = {
  getRegistration(state) {
    return state.registration;
  },
  getRegistrationErrors(state) {
    return state.registrationErrors;
  },
  getRegistrationNonFieldErrors(state) {
    return getNonFieldErrors(state.registrationErrors);
  }
};

const mutations = {
  setRegistration(state, registration) {
    state.registration = { ...registration };
    state.registrationErrors = {};
  },
  setRegistrationProp(state, { prop, value }) {
    state.registration = { ...state.registration, [prop]: value };
    state.registrationErrors = { ...state.registrationErrors, [prop]: null };
  },
  setRegistrationErrors(state, errors) {
    state.registrationErrors = { ...errors };
  }
};

const actions = {
  async register({ state, commit, getters }) {
    const http = new Http({ auth: false });
    try {
      await http.fetch('post', REGISTRATION_URL, { data: state.registration });
      commit('snackbar/showMessage', { message: i18n.t('$t.registeredDot') }, { root: true });
      return true;
    } catch (err) {
      commit('setRegistrationErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getRegistrationNonFieldErrors);
      errorHandler.handle();
      return false;
    }
  },
  async confirmRegistration({ commit }, { token, userId }) {
    try {
      const http = new Http();
      const url = new Url(REGISTRATION_CONFIRMATION_URL, { token, userId });
      const response = await http.fetch('patch', url.toString());
      commit(
        'snackbar/showMessage',
        { message: response.data.detail },
        { root: true });
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
