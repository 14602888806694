import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const LEGAL_FILES_URL = '/api/v1/docs/legal-files/:type/';
const LEGAL_FILE_TYPES_URL = '/api/v1/docs/legal-files/types/';

const state = {
  files: [],
  filesLoading: false,
  file: {},
  fileErrors: {},
  fileLoading: false,
  types: []
};

const getters = {
  getLegalFiles(state) {
    return state.files;
  },
  getTermsOfServiceUrl(state) {
    const fd = state.files.find(f => f.type === 'terms-of-service');
    return fd && fd.file;
  },
  getPrivacyPolicyUrl(state) {
    const fd = state.files.find(f => f.type === 'privacy-policy');
    return fd && fd.file;
  },
  isLegalFilesLoading(state) {
    return state.filesLoading;
  },
  getLegalFile(state) {
    return state.file;
  },
  getLegalFileErrors(state) {
    return state.fileErrors;
  },
  getLegalFileNonFieldERrors(state) {
    return getNonFieldErrors(state.fileErrors);
  },
  isLegalFileLoading(state) {
    return state.fileLoading;
  },
  getLegalFileTypes(state) {
    return state.types;
  }
};

const mutations = {
  setLegalFiles(state, files) {
    state.files = [...files];
  },
  appendLegalFiles(state, files) {
    state.files = [...state.files, ...files];
  },
  updateLegalFiles(state, file) {
    state.files = assignIf(state.files, f => f.type === file.type, file);
  },
  deleteLegalFiles(state, file) {
    state.files = deleteIf(state.files, f => f.type === file.type);
  },
  setLegalFilesLoading(state, loading) {
    state.filesLoading = loading;
  },
  setLegalFile(state, file) {
    state.file = { ...file };
    state.fileErrors = {};
  },
  setLegalFileProp(state, { prop, value }) {
    state.file = { ...state.file, [prop]: value };
    state.fileErrors = { ...state.fileErrors, [prop]: null };
  },
  setLegalFileErrors(state, errors) {
    state.fileErrors = { ...errors };
  },
  setLegalFileLoading(state, loading) {
    state.fileLoading = loading;
  },
  setLegalFileTypes(state, types) {
    state.types = [...types];
  }
};

const actions = {
  async fetchLegalFiles({ commit }, { params = {} } = {}) {
    try {
      commit('setLegalFilesLoading', true);
      const rest = new Rest();
      const url = new Url(LEGAL_FILES_URL, { type: null });
      const response = await rest.get(url.toString(), params);
      commit('setLegalFiles', response.data);
      return true;
    } catch (err) {
      commit('setLegalFiles', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setLegalFilesLoading', false);
    }
  },
  async fetchLegalFile({ commit }, { type, params = {} }) {
    try {
      commit('setLegalFileLoading', true);
      const rest = new Rest();
      const url = new Url(LEGAL_FILES_URL, { type });
      const response = await rest.get(url.toString(), params);
      commit('setLegalFile', response.data);
      return true;
    } catch (err) {
      commit('setLegalFile', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setLegalFileLoading', false);
    }
  },
  async saveLegalFile({ state, getters, commit }) {
    try {
      commit('setLegalFileLoading', true);
      const rest = new Rest();
      const url = new Url(LEGAL_FILES_URL, { type: state.file.type });
      let response = {};
      if (state.file.file instanceof Blob) {
        const form = new FormData();
        for (const prop in state.file) {
          form.append(prop, state.file[prop]);
        }
        response = await rest.put(
          url.toString(), form, { headers: { 'Content-Type': 'multipart/form' } });
      } else if (state.file.file === null) {
        response = await rest.put(url.toString(), state.file);
      } else {
        // eslint-disable-next-line no-unused-vars
        const { image, ...data } = state.file;
        response = await rest.put(url.toString(), data);
      }
      commit('setLegalFile', response.data);
      commit('updateLegalFiles', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setLegalFileErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getLegalFileNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setLegalFileLoading', false);
    }
  },
  async fetchLegalFileTypes({ commit }, { params = {} } = {}) {
    try {
      const rest = new Rest();
      const response = await rest.get(LEGAL_FILE_TYPES_URL, params);
      commit('setLegalFileTypes', response.data);
      return true;
    } catch (err) {
      commit('setLegalFileTypes', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
