import { loadData, saveData } from '@/tog/store/utils';

export const SUPERUSER = 'superuser';
export const STAFF = 'staff';
export const CUSTOMER = 'customer';

const state = {
  userType: loadData('auth/userType', null)
};

const getters = {
  getUserType(state) {
    return state.userType;
  },
  isSuperUser(state) {
    return state.userType === SUPERUSER;
  },
  isStaff(state) {
    return state.userType === STAFF;
  },
  isCustomer(state) {
    return state.userType === CUSTOMER;
  }
};

const mutations = {
  setUserType(state, userType) {
    state.userType = userType;
    saveData('auth/userType', state.userType);
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};


