export function objectHandler(setter) {
  return {
    get(obj, prop) {
      if (!(prop in obj)) {
        setter({ prop, value: undefined });
      }
      return obj[prop];
    },
    set(obj, prop, value) {
      setter({ prop, value });
      return true;
    }
  };
}

export function assignAt(array, index, obj) {
  return [
    ...array.slice(0, index === -1 ? 0 : index),
    obj,
    ...array.slice(index + 1)];
}

export function assignIf(array, predicate, obj) {
  const index = array.findIndex(predicate);
  return assignAt(array, index, obj);
}

export function deleteAt(array, index) {
  return [
    ...array.slice(0, index === -1 ? 0 : index),
    ...array.slice(index + 1)];
}

export function deleteIf(array, predicate) {
  const index = array.findIndex(predicate);
  return deleteAt(array, index);
}

export function getNonFieldErrors(errors) {
  return errors.non_field_errors ? errors.non_field_errors.join(' ') : '';
}

export function loadData(key, defaultValue = null) {
  try {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  } catch {
    return defaultValue;
  }
}

export function saveData(key, val) {
  localStorage.setItem(key, JSON.stringify(val));
}
