<template>
  <v-menu
    v-model="menu"
    offset-y
    min-width="360"
    rounded="b-lg"
    :close-on-content-click="true"
  >
    <template #activator="menuActivator">
      <v-tooltip bottom>
        <template #activator="tooltipActivator">
          <v-btn
            v-on="{ ...menuActivator.on, ...tooltipActivator.on }"
            v-bind="{ ...menuActivator.attrs, ...tooltipActivator.attrs }"
            icon
          ><v-icon color="secondary">$mdiAccountOutline</v-icon></v-btn>
        </template>
        <span v-t="'$t.myAccount'"></span>
      </v-tooltip>
    </template>

    <v-card>
      <v-list>
        <v-list-item
          color="primary"
          :to="{ name: 'profile:update' }"
        >
          <v-list-item-icon>
            <v-icon>$mdiAccountOutline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-t="'$t.myData'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          color="primary"
          :to="{ name: 'tours:self-list', params: { status: 'draft' } }"
        >
          <v-list-item-icon>
            <v-icon>$mdiChatQuestion</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-t="'$t.requestsSelf'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          color="primary"
          @click="signout"
        >
          <v-list-item-icon>
            <v-icon>$mdiLogout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-t="'$t.logOut'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      menu: false
    };
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    async signout() {
      if (await this.logout()) {
        this.$router.push({ name: 'auth:login' });
      }
    }
  }
};
</script>
