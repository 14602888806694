import { isAuthorized } from '@/tog/router/utils';

export default [{
  path: '/destinations/',
  name: 'tours:destinations:list',
  beforeEnter: isAuthorized,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/destination-list')
}, {
  path: '/destinations/:id/attractions/',
  name: 'tours:attractions:list',
  beforeEnter: isAuthorized,
  props: true,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/attraction-list')
}, {
  path: '/destinations/:destinationId/attractions/:attractionId/media/',
  name: 'tours:attractions:media:list',
  beforeEnter: isAuthorized,
  props: true,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/attraction-media-list')
}, {
  path: '/destinations/dashboard/',
  name: 'destination:select',
  beforeEnter: isAuthorized,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/destination-select')
}, {
  path: '/tours/',
  name: 'tours:list',
  beforeEnter: isAuthorized,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/tour-list')
}, {
  path: '/tours/new/',
  name: 'tours:create',
  beforeEnter: isAuthorized,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/tour-create')
}, {
  path: '/tours/:id/',
  name: 'tours:update',
  beforeEnter: isAuthorized,
  props: true,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/tour-update')
}, {
  path: '/tours/:id/preview/:stage/',
  name: 'tours:preview',
  props: true,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/tour-preview'),
  children: [{
    path: 'detail/',
    name: 'tours:preview:detail',
    props: { default: true, minor: true },
    components: {
      minor: () => import(
        /* webpackChunkName: 'comments' */ '@/comments/views/tour-comment-list'),
    }
  }]
}, {
  path: '/tours/:id/share/',
  name: 'tours:share',
  redirect: to => ({ name: 'tours:preview:detail', params: { id: to.params.id, stage: '-' } })
}, {
  path: '/tours/self/:status/',
  name: 'tours:self-list',
  beforeEnter: isAuthorized,
  props: true,
  component: () => import(/* webpackChunkName: 'tours' */ '@/tours/views/tour-self-list')
}];
