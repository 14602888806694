import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const TOURS_URL = '/api/v1/tours/tours/:id/';
const TOURS_SENDING_URL = '/api/v1/tours/tours/:id/sending/';
const TOURS_ARCHIVING_URL = '/api/v1/tours/tours/:id/archiving/';
const TOURS_UNARCHIVING_URL = '/api/v1/tours/tours/:id/unarchiving/';
const TOURS_PREVIEW_URL = '/api/v1/tours/tours/:id/preview/:stage/';
const STATUSES_URL = '/api/v1/tours/tours/statuses/';
const AGE_STUDENTS_URL = '/api/v1/tours/tours/age-students/';
const AIRPORTS_URL = '/api/v1/tours/tours/airports/';
const FOODS_URL = '/api/v1/tours/tours/foods/';
const LENGTHS_URL = '/api/v1/tours/tours/lengths/';

const state = {
  tours: [],
  toursServerLen: 0,
  toursFilter: false,
  toursLoading: false,
  tour: {},
  tourErrors: {},
  tourLoading: false,
  statuses: [],
  ageStudents: [],
  ageStudentsLoading: false,
  airports: [],
  airportsLoading: false,
  foods: [],
  foodsLoading: false,
  lengths: {},
  lengthsLoading: false
};

const getters = {
  getTours(state) {
    return state.tours;
  },
  getToursServerLen(state) {
    return state.toursServerLen;
  },
  isToursFilterOpen(state) {
    return state.toursFilter;
  },
  isToursLoading(state) {
    return state.toursLoading;
  },
  getTour(state) {
    return state.tour;
  },
  getTourErrors(state) {
    return state.tourErrors;
  },
  getTourNonFieldErrors(state) {
    return getNonFieldErrors(state.tourErrors);
  },
  isTourLoading(state) {
    return state.tourLoading;
  },
  getStatuses(state) {
    return state.statuses;
  },
  getAgeStudents(state) {
    return state.ageStudents;
  },
  isAgeStudentsLoading(state) {
    return state.ageStudentsLoading;
  },
  getAirports(state) {
    return state.airports;
  },
  isAirportsLoading(state) {
    return state.airportsLoading;
  },
  getFoods(state) {
    return state.foods;
  },
  isFoodsLoading(state) {
    return state.foodsLoading;
  },
  getLengths(state) {
    return state.lengths;
  },
  isLengthsLoading(state) {
    return state.lengthsLoading;
  }
};

const mutations = {
  setTours(state, { tours, serverLen = null }) {
    state.tours = [...tours];
    state.toursServerLen = serverLen || tours.length;
  },
  appendTours(state, { tours, serverLen = null }) {
    state.tours = [...state.tours, ...tours];
    state.toursServerLen = serverLen || tours.length;
  },
  updateTours(state, tour) {
    const oldLen = state.tours.length;
    state.tours = assignIf(state.tours, t => t.id === tour.id, tour);
    state.toursServerLen += state.tours.length - oldLen;
  },
  deleteTours(state, tour) {
    const oldLen = state.tours.length;
    state.tours = deleteIf(state.tours, t => t.id === tour.id);
    state.toursServerLen += state.tours.length - oldLen;
    state.lengths[tour.status]--;
  },
  setToursFilterOpen(state, filter) {
    state.toursFilter = filter;
  },
  setToursLoading(state, loading) {
    state.toursLoading = loading;
  },
  setTour(state, tour) {
    state.tour = { ...tour };
    state.tourErrors = {};
  },
  setTourProp(state, { prop, value }) {
    state.tour = { ...state.tour, [prop]: value };
    state.tourErrors = { ...state.tourErrors, [prop]: null };
  },
  setTourErrors(state, errors) {
    state.tourErrors = { ...errors };
  },
  setTourLoading(state, loading) {
    state.tourLoading = loading;
  },
  setStatuses(state, statuses) {
    state.statuses = [...statuses];
  },
  setAgeStudents(state, ages) {
    state.ageStudents = [...ages];
  },
  setAgeStudentsLoading(state, loading) {
    state.ageStudentsLoading = loading;
  },
  setAirports(state, airports) {
    state.airports = [...airports];
  },
  setAirportsLoading(state, loading) {
    state.airportsLoading = loading;
  },
  setFoods(state, foods) {
    state.foods = [...foods];
  },
  setFoodsLoading(state, loading) {
    state.foodsLoading = loading;
  },
  setLengths(state, lengths) {
    state.lengths = { ...lengths };
  },
  setLengthsLoading(state, loading) {
    state.lengthsLoading = loading;
  }
};

const actions = {
  async fetchTours({ commit }, { params = {}, append = false } = {}) {
    try {
      commit('setToursLoading', true);
      const rest = new Rest();
      const url = new Url(TOURS_URL, { id: null });
      const response = await rest.get(url.toString(), params);
      const mutator = append ? 'appendTours' : 'setTours';
      commit(mutator, {
        tours: response.data.results,
        serverLen: response.data.count
      });
      return true;
    } catch (err) {
      commit('setTours', { tours: [], serverLen: 0 });
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setToursLoading', false);
    }
  },
  async fetchTour({ commit }, { id, params = {} }) {
    try {
      commit('setTourLoading', true);
      const rest = new Rest();
      const url = new Url(TOURS_URL, { id });
      const response = await rest.get(url.toString(), params);
      commit('setTour', response.data);
      return true;
    } catch (err) {
      commit('setTour', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setTourLoading', false);
    }
  },
  async fetchTourPreview({ commit }, { id, stage, params = {} }) {
    try {
      commit('setTourLoading', true);
      const rest = new Rest();
      const url = new Url(TOURS_PREVIEW_URL, { id, stage });
      const response = await rest.get(url.toString(), params);
      commit('setTour', response.data);
      return true;
    } catch (err) {
      commit('setTour', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.set404Redirect(true);
      errorHandler.handle();
      return false;
    } finally {
      commit('setTourLoading', false);
    }
  },
  async saveTour({ state, getters, commit }) {
    try {
      commit('setTourLoading', true);
      const rest = new Rest();
      const url = new Url(TOURS_URL, { id: state.tour.id });
      const save = state.tour.id ? rest.patch : rest.post;
      const response = await save.call(rest, url.toString(), state.tour);
      commit('setTour', response.data);
      commit('updateTours', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setTourErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getTourNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setTourLoading', false);
    }
  },
  async sendTour({ state, getters, commit }) {
    try {
      commit('setTourLoading', true);
      const rest = new Rest();
      const url = new Url(TOURS_SENDING_URL, { id: state.tour.id });
      const response = await rest.post(url.toString(), state.tour);
      commit('setTour', response.data);
      commit('updateTours', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.formSentDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setTourErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getTourNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setTourLoading', false);
    }
  },
  async archiveTour({ state, getters, commit }) {
    try {
      const rest = new Rest();
      const url = new Url(TOURS_ARCHIVING_URL, { id: state.tour.id });
      const response = await rest.post(url.toString(), state.tour);
      commit('setTour', response.data);
      commit('updateTours', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.requestArchivedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setTourErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getTourNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    }
  },
  async unarchiveTour({ state, getters, commit }) {
    try {
      const rest = new Rest();
      const url = new Url(TOURS_UNARCHIVING_URL, { id: state.tour.id });
      const response = await rest.post(url.toString(), state.tour);
      commit('setTour', response.data);
      commit('updateTours', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.requestUnarchivedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setTourErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getTourNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    }
  },
  async deleteTour({ commit }, tour) {
    try {
      const rest = new Rest();
      const url = new Url(TOURS_URL, { id: tour.id });
      await rest.delete(url.toString());
      commit('deleteTours', tour);
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.deleteErrorDot') });
      errorHandler.handle();
      return false;
    }
  },
  async fetchStatuses({ commit }, { params = {} } = {}) {
    try {
      const rest = new Rest();
      const response = await rest.get(STATUSES_URL, params);
      commit('setStatuses', response.data);
      return true;
    } catch (err) {
      commit('setStatuses', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  },
  async fetchAgeStudents({ commit }, { params = {} } = {}) {
    try {
      commit('setAgeStudentsLoading', true);
      const rest = new Rest();
      const response = await rest.get(AGE_STUDENTS_URL, params);
      commit('setAgeStudents', response.data);
      return true;
    } catch (err) {
      commit('setAgeStudents', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAgeStudentsLoading', false);
    }
  },
  async fetchAirports({ commit }, { params = {} } = {}) {
    try {
      commit('setAirportsLoading', true);
      const rest = new Rest();
      const response = await rest.get(AIRPORTS_URL, params);
      commit('setAirports', response.data);
      return true;
    } catch (err) {
      commit('setAirports', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAirportsLoading', false);
    }
  },
  async fetchFoods({ commit }, { params = {} } = {}) {
    try {
      commit('setFoodsLoading', true);
      const rest = new Rest();
      const response = await rest.get(FOODS_URL, params);
      commit('setFoods', response.data);
      return true;
    } catch (err) {
      commit('setFoods', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setFoodsLoading', false);
    }
  },
  async fetchLengths({ commit }, { params = {} } = {}) {
    try {
      commit('setLengthsLoading', true);
      const rest = new Rest();
      const response = await rest.get(LENGTHS_URL, params);
      commit('setLengths', response.data);
      return true;
    } catch (err) {
      commit('setLengths', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setLengthsLoading', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
