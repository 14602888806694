import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const USERS_URL = '/api/v1/users/users/:id/';
const USERS_PASSWORD_RETRIEVAL_URL = '/api/v1/users/users/:id/password-retrieval/';
const TYPES_URL = '/api/v1/users/users/types/';
const ACCOUNTS_URL = '/api/v1/users/users/accounts/';

const state = {
  users: [],
  usersServerLen: 0,
  usersFilter: false,
  usersLoading: false,
  user: {},
  userErrors: {},
  userLoading: false,
  types: [],
  accounts: []
};

const getters = {
  getUsers(state) {
    return state.users;
  },
  getUsersServerLen(state) {
    return state.usersServerLen;
  },
  isUsersFilterOpen(state) {
    return state.usersFilter;
  },
  isUsersLoading(state) {
    return state.usersLoading;
  },
  getUser(state) {
    return state.user;
  },
  getUserErrors(state) {
    return state.userErrors;
  },
  getUserNonFieldErrors(state) {
    return getNonFieldErrors(state.userErrors);
  },
  isUserLoading(state) {
    return state.userLoading;
  },
  getTypes(state) {
    return state.types;
  },
  getAccounts(state) {
    return state.accounts;
  }
};

const mutations = {
  setUsers(state, { users, serverLen = null }) {
    state.users = [...users];
    state.usersServerLen = serverLen || users.length;
  },
  appendUsers(state, { users, serverLen = null }) {
    state.users = [...state.users, ...users];
    state.usersServerLen = serverLen || users.length;
  },
  updateUsers(state, user) {
    const oldLen = state.users.length;
    state.users = assignIf(state.users, t => t.id === user.id, user);
    state.usersServerLen += state.users.length - oldLen;
  },
  deleteUsers(state, user) {
    const oldLen = state.users.length;
    state.users = deleteIf(state.users, t => t.id === user.id);
    state.usersServerLen += state.users.length - oldLen;
    state.lengths[user.status]--;
  },
  setUsersFilterOpen(state, filter) {
    state.usersFilter = filter;
  },
  setUsersLoading(state, loading) {
    state.usersLoading = loading;
  },
  setUser(state, user) {
    state.user = { ...user };
  },
  setUserProp(state, { prop, value }) {
    state.user = { ...state.user, [prop]: value };
    state.userErrors = { ...state.userErrors, [prop]: null };
  },
  setUserErrors(state, errors) {
    state.userErrors = { ...errors };
  },
  setUserLoading(state, loading) {
    state.userLoading = loading;
  },
  setTypes(state, types) {
    state.types = [...types];
  },
  setAccounts(state, accounts) {
    state.accounts = [...accounts];
  }
};

const actions = {
  async fetchUsers({ commit }, { params = {}, append = false } = {}) {
    try {
      commit('setUsersLoading', true);
      const rest = new Rest();
      const url = new Url(USERS_URL, { id: null });
      const response = await rest.get(url.toString(), params);
      const mutator = append ? 'appendUsers' : 'setUsers';
      commit(mutator, {
        users: response.data.results,
        serverLen: response.data.count
      });
      return true;
    } catch (err) {
      commit('setUsers', { users: [], serverLen: 0 });
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setUsersLoading', false);
    }
  },
  async fetchUser({ commit }, { id, params = {} }) {
    try {
      commit('setUserLoading', true);
      const rest = new Rest();
      const url = new Url(USERS_URL, { id });
      const response = await rest.get(url.toString(), params);
      commit('setUser', response.data);
      return true;
    } catch (err) {
      commit('setUser', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setUserLoading', false);
    }
  },
  async saveUser({ state, getters, commit }) {
    try {
      commit('setUserLoading', true);
      const rest = new Rest();
      const url = new Url(USERS_URL, { id: state.user.id });
      const save = state.user.id ? rest.patch : rest.post;
      const response = await save.call(rest, url.toString(), state.user);
      commit('setUser', response.data);
      commit('updateUsers', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setUserErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getUserNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setUserLoading', false);
    }
  },
  async retrievePassword({ commit }, { user }) {
    try {
      const rest = new Rest();
      const url = new Url(USERS_PASSWORD_RETRIEVAL_URL, { id: user.id });
      await rest.post(url.toString());
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.sentEmailWithResetLink') },
        { root: true });
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  },
  async fetchTypes({ commit }, { params = {} } = {}) {
    try {
      const rest = new Rest();
      const response = await rest.get(TYPES_URL, params);
      commit('setTypes', response.data);
      return true;
    } catch (err) {
      commit('setTypes', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  },
  async fetchAccounts({ commit }, { params = {} } = {}) {
    try {
      const rest = new Rest();
      const response = await rest.get(ACCOUNTS_URL, params);
      commit('setAccounts', response.data);
      return true;
    } catch (err) {
      commit('setAccounts', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
