import Vue from 'vue';
import Vuex from 'vuex';

import snackbar from '@/tog/store/snackbar';
import progressbar from '@/tog/store/progressbar';
import auth from '@/auth/store';
import users from '@/users/store';
import tours from '@/tours/store';
import comments from '@/comments/store';
import docs from '@/docs/store';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    snackbar,
    progressbar,
    auth,
    users,
    tours,
    comments,
    docs
  }
});
