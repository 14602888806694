import Http from '@/tog/services/http';

class Rest extends Http {
  get(url, params, config = {}) {
    return super.fetch('get', url, { params, ...config });
  }

  post(url, data, config = {}) {
    return super.fetch('post', url, { data, ...config });
  }

  patch(url, data, config = {}) {
    return super.fetch('patch', url, { data, ...config });
  }

  put(url, data, config = {}) {
    return super.fetch('put', url, { data, ...config });
  }

  delete(url, params, config = {}) {
    return super.fetch('delete', url, { params, ...config });
  }
}

function tableOptionsAdapter(tableOptions, rest) {
  const restOptions = {
    offset: (tableOptions.page - 1) * tableOptions.itemsPerPage,
    limit: tableOptions.itemsPerPage,
    ...rest
  };
  if (tableOptions.sortBy && tableOptions.sortBy.length) {
    const orderingSign = tableOptions.sortDesc[0] ? '-' : '';
    restOptions.ordering = `${orderingSign}${tableOptions.sortBy[0]}`;
  }
  return restOptions;
}

export default Rest;
export { tableOptionsAdapter };
