import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import ErrorHandler from '@/tog/services/error-handler';
import { getNonFieldErrors } from '@/tog/store/utils';

const PROFILE_URL = '/api/v1/users/self/profile/';

const state = {
  profile: {},
  profileErrors: {},
  profileLoading: false
};

const getters = {
  getProfile(state) {
    return state.profile;
  },
  getProfileErrors(state) {
    return state.profileErrors;
  },
  getProfileNonFieldErrors(state) {
    return getNonFieldErrors(state.profileErrors);
  },
  isProfileLoading(state) {
    return state.profileLoading;
  }
};

const mutations = {
  setProfile(state, profile) {
    state.profile = { ...profile };
    state.profileErrors = {};
  },
  setProfileProp(state, { prop, value }) {
    state.profile = { ...state.profile, [prop]: value };
    state.profileErrors = { ...state.profileErrors, [prop]: null };
  },
  setProfileErrors(state, errors) {
    state.profileErrors = { ...errors };
  },
  setProfileLoading(state, loading) {
    state.profileLoading = loading;
  }
};

const actions = {
  async fetchProfile({ commit }, { params = {} } = {}) {
    try {
      commit('setProfileLoading', true);
      const rest = new Rest();
      const response = await rest.get(PROFILE_URL, params);
      commit('setProfile', response.data);
      return true;
    } catch (err) {
      commit('setProfile', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setProfileLoading', false);
    }
  },
  async saveProfile({ state, getters, commit }) {
    try {
      commit('setProfileLoading', true);
      const rest = new Rest();
      const response = await rest.patch(PROFILE_URL, state.profile);
      commit('setProfile', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setProfileErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getProfileNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setProfileLoading', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
