import axios from 'axios';
import i18n from '@/plugins/i18n';
import store from '@/tog/store';
import router from '@/tog/router';

class ErrorHandler {
  unexpectedError = i18n.t('$t.unexpectedErrorDot');
  restStatusCodeErrors = {
    400: i18n.t('$t.generalErrorDot'),
    401: i18n.t('$t.unauthorizedErrorDot'),
    403: i18n.t('$t.forbiddenErrorDot'),
    404: i18n.t('$t.notFoundErrorDot'),
    405: i18n.t('$t.methodNotAllowedErrorDot'),
    408: i18n.t('$t.timeoutErrorDot'),
    500: i18n.t('$t.internalServerErrorDot')
  };

  constructor(err) {
    this.err = err;
    this.prefix = null;
    this.suffix = null;
    this.color = 'error';
    this.block = false;
    this.redirect404 = false;
  }

  setPrefix(msg) {
    this.prefix = msg;
  }

  setSuffix(msg) {
    this.suffix = msg;
  }

  setColor(color) {
    this.color = color;
  }

  setBlockMode(block) {
    this.block = block;
  }

  set404Redirect(redirect) {
    this.redirect404 = redirect;
  }

  updateRestStatusCodeErrors(errors) {
    this.restStatusCodeErrors = { ...this.restStatusCodeErrors, ...errors };
  }

  handle() {
    let message = null;

    if (axios.isAxiosError(this.err)) {
      const response = this.err.response;
      if (response.data?.detail) {
        message = response.data.detail;
      } else if (response.data?.details) {
        message = response.data.details.join(' ');
      } else {
        message = this.restStatusCodeErrors[this.err.response.status] || this.unexpectedError;
      }
    } else {
      message = this.unexpectedError;
    }

    if (this.prefix) {
      message = `${this.prefix} ${message}`;
    }

    if (this.suffix) {
      message = `${message} ${this.suffix}`;
    }

    if (this.redirect404 && this.err.response.status === 404) {
      router.push({ name: 'not-found' });
    } else {
      store.commit(
        'snackbar/showMessage',
        { message, color: this.color, block: this.block },
        { root: true });
    }
    return message;
  }
}

export default ErrorHandler;
