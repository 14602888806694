import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const ATTRACTIONS_URL = '/api/v1/tours/attractions/:id/';
const ATTRACTIONS_ARRAY_URL = '/api/v1/tours/attractions/array/';

const state = {
  attractions: [],
  attractionsServerLen: 0,
  attractionsArray: [],
  attractionsLoading: false,
  attractionsArrayLoading: false,
  attraction: {},
  attractionErrors: {},
  attractionLoading: false
};

const getters = {
  getAttractions(state) {
    return state.attractions;
  },
  getAttractionsServerLen(state) {
    return state.attractionsServerLen;
  },
  getAttractionsArray(state) {
    return state.attractionsArray;
  },
  isAttractionsLoading(state) {
    return state.attractionsLoading;
  },
  isAttractionsArrayLoading(state) {
    return state.attractionsArrayLoading;
  },
  getAttraction(state) {
    return state.attraction;
  },
  getAttractionErrors(state) {
    return state.attractionErrors;
  },
  getAttractionNonFieldErrors(state) {
    return getNonFieldErrors(state.attractionErrors);
  },
  isAttractionLoading(state) {
    return state.attractionLoading;
  }
};

const mutations = {
  setAttractions(state, { attractions, serverLen = null }) {
    state.attractions = [...attractions];
    state.attractionsServerLen = serverLen || attractions.length;
  },
  appendAttractions(state, { attractions, serverLen = null }) {
    state.attractions = [...state.attractions, ...attractions];
    state.attractionsServerLen = serverLen || attractions.length;
  },
  updateAttractions(state, attraction) {
    const oldLen = state.attractions.length;
    state.attractions = assignIf(state.attractions, a => a.id === attraction.id, attraction);
    state.attractionsServerLen += state.attractions.length - oldLen;
  },
  deleteAttractions(state, attraction) {
    const oldLen = state.attractions.length;
    state.attractions = deleteIf(state.attractions, a => a.id === attraction.id);
    state.attractionsServerLen += state.attractions.length - oldLen;
  },
  setAttractionsArray(state, attractions) {
    state.attractionsArray = [...attractions];
  },
  setAttractionsLoading(state, loading) {
    state.attractionsLoading = loading;
  },
  setAttractionsArrayLoading(state, loading) {
    state.attractionsArrayLoading = loading;
  },
  setAttraction(state, attraction) {
    state.attraction = { ...attraction };
    state.attractionErrors = {};
  },
  setAttractionProp(state, { prop, value }) {
    state.attraction = { ...state.attraction, [prop]: value };
    state.attractionErrors = { ...state.attractionErrors, [prop]: null };
  },
  setAttractionErrors(state, errors) {
    state.attractionErrors = { ...errors };
  },
  setAttractionLoading(state, loading) {
    state.attractionLoading = loading;
  }
};

const actions = {
  async fetchAttractions({ commit }, { params = {}, append = false } = {}) {
    try {
      commit('setAttractions', { attractions: [], serverLen: 0 });
      commit('setAttractionsLoading', true);
      const rest = new Rest();
      const url = new Url(ATTRACTIONS_URL, { id: null });
      const response = await rest.get(url.toString(), params);
      const mutator = append ? 'appendAttractions' : 'setAttractions';
      commit(mutator, {
        attractions: response.data.results,
        serverLen: response.data.count
      });
      return true;
    } catch (err) {
      commit('setAttractions', { attractions: [], serverLen: 0 });
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionsLoading', false);
    }
  },
  async fetchAttractionsArray({ commit }, { params = {} } = {}) {
    try {
      commit('setAttractionsArray', []);
      commit('setAttractionsArrayLoading', true);
      const rest = new Rest();
      const response = await rest.get(ATTRACTIONS_ARRAY_URL, params);
      commit('setAttractionsArray', response.data);
      return true;
    } catch (err) {
      commit('setAttractionsArray', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionsArrayLoading', false);
    }
  },
  async fetchAttraction({ commit }, { id, params = {} }) {
    try {
      commit('setAttraction', {});
      commit('setAttractionLoading', true);
      const rest = new Rest();
      const url = new Url(ATTRACTIONS_URL, { id });
      const response = await rest.get(url.toString(), params);
      commit('setAttraction', response.data);
      return true;
    } catch (err) {
      commit('setAttraction', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionLoading', false);
    }
  },
  async saveAttraction({ state, getters, commit }) {
    try {
      commit('setAttractionLoading', true);
      const rest = new Rest();
      const url = new Url(ATTRACTIONS_URL, { id: state.attraction.id });
      const save = state.attraction.id ? rest.patch : rest.post;
      const response = await save.call(rest, url.toString(), state.attraction);
      commit('setAttraction', response.data);
      commit('updateAttractions', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setAttractionErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getAttractionNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionLoading', false);
    }
  },
  async deleteAttraction({ commit }, attraction) {
    try {
      commit('setAttractionLoading', true);
      const rest = new Rest();
      const url = new Url(ATTRACTIONS_URL, { id: attraction.id });
      await rest.delete(url.toString());
      commit('deleteAttractions', attraction);
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.deleteErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setAttractionLoading', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
