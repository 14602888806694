import Vue from 'vue';

import App from '@/App.vue';
import router from '@/tog/router';
import store from '@/tog/store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';

import '@/style.css';

import { DATE_OPTIONS, DATE_TIME_OPTIONS } from '@/tog/settings';

Vue.config.productionTip = false;

Vue.filter('mapFields', (value, from, to, list) => {
  const found = list.find(item => item[from] === value);
  return found && found[to];
});

Vue.filter('truncate', (str, n = 3) => {
  const t = str.split(/\s/);
  return t.slice(0, n).join(' ') + (t.length > n ? '...' : '');
});

Vue.filter('formatDate', (date, style = {}) =>
  date ? (new Date(date)).toLocaleDateString('pl', { ...DATE_OPTIONS, ...style }) : '-'
);

Vue.filter('formatTime', time =>
  time ? time.match(/[0-2][0-9]:[0-5][0-9]/g)[0] : '-'
);

Vue.filter('formatDateTime', (datetime, style = {}) =>
  datetime ? (new Date(datetime)).toLocaleString('pl', { ...DATE_TIME_OPTIONS, ...style }) : '-'
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
