<template>
  <v-app>
    <v-snackbar
      v-model="snackbar"
      bottom
      :color="snackbarColor"
    >{{ snackbarMessage }}</v-snackbar>

    <v-app-bar
      v-if="isAuth"
      app
      flat
      color="white"
    >
      <router-link class="fill-height" :to="{ name: 'home' }">
        <v-img
          src="@/assets/logo.svg"
          contain
          width="148px"
          height="100%"
        ></v-img>
      </router-link>

      <v-progress-linear
        :active="isProgressbarActive"
        :indeterminate="isProgressbarIndetermine"
        absolute
        bottom
        color="primary"
        background-color="grey lighten-4"
      ></v-progress-linear>

      <v-spacer></v-spacer>

      <v-tooltip
        bottom
        :disabled="$vuetify.breakpoint.smAndUp"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            large
            depressed
            rounded
            color="secondary"
            :icon="$vuetify.breakpoint.xs"
            :to="{ name: 'tours:create' }"
          >
            <v-icon large>$mdiPlus</v-icon>
            <span v-t="'$t.planTour'" class="d-none d-sm-flex"></span>
          </v-btn>
        </template>
        <span v-t="'$t.planTour'"></span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            icon
            exact
            :to="{ name: 'home' }"
          >
            <v-icon color="secondary">$mdiHome</v-icon>
          </v-btn>
        </template>
        <span v-t="'$t.mainPage'"></span>
      </v-tooltip>

      <staff-widget v-if="isSuperUser || isStaff"></staff-widget>
      <profile-widget></profile-widget>
    </v-app-bar>

    <v-main id="main">
      <router-view></router-view>
    </v-main>

    <v-footer
      id="footer"
      class="overflow-auto"
      app
      height="164px"
    >
      <v-img
        src="@/assets/eu-logo.png"
        contain
        max-height="100px"
      ></v-img>
      <p class="ma-0 footer--text">
        Realizujemy projekt pod tytułem „Internetowy system
        bezobsługowej realizacji zapytań ofertowych, którego celem
        jest ograniczenie kontaktów bezpośrednich pomiędzy klientami a
        pracownikami biura podróży ORIZ lzabela Orłowska”. Efektem
        projektu będzie cyfryzacja części procesów, która pozwoli
        zaoszczędzić czas pracy pracowników co umożliwi obsłużenie
        większej liczby klientów. Wartość projektu wynosi: 289 900,00
        PLN, z czego 246 415,00 PLN dofinansowane ze środków
        UE. Sfinansowano w ramach reakcji Unii Europejskiej na
        pandemię COVID-19.
      </p>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import StaffWidget from '@/tog/components/staff-widget';
import ProfileWidget from '@/tog/components/profile-widget';

export default {
  name: 'App',
  components: { StaffWidget, ProfileWidget },
  computed: {
    ...mapGetters({
      isAuth: 'auth/isAuth',
      isSuperUser: 'auth/isSuperUser',
      isStaff: 'auth/isStaff',
      snackbarMessage: 'snackbar/getMessage',
      snackbarColor: 'snackbar/getColor',
      isSnackbarOpened: 'snackbar/isOpened',
      isProgressbarActive: 'progressbar/isActive',
      isProgressbarIndetermine: 'progressbar/isIndetermine'
    }),
    snackbar: {
      get() {
        return this.isSnackbarOpened;
      },
      set() {
        this.hideMessage();
      }
    },
  },
  methods: {
    ...mapMutations({
      hideMessage: 'snackbar/hideMessage'
    })
  }
};
</script>

<style lang="scss" scoped>
#main {
  background-color: var(--background-color);
}

#footer {
  .footer--text {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.0rem;

    @media screen and (max-width: 960px) {
      font-size: 0.6rem;
      line-height: 0.9rem;
    }
  }
}
</style>
