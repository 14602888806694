import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const COMMENTS_URL = '/api/v1/comments/tours/:tourId/comments/:id/';
const SEEN_COMMENTS_URL = '/api/v1/comments/tours/:tourId/comments/seen/';

const state = {
  comments: [],
  commentsServerLen: 0,
  commentsLoading: false,
  comment: {},
  commentErrors: {},
  commentLoading: false
};

const getters = {
  getComments(state) {
    return state.comments;
  },
  getCommentsServerLen(state) {
    return state.commentsServerLen;
  },
  isCommentsLoading(state) {
    return state.commentsLoading;
  },
  getComment(state) {
    return state.comment;
  },
  getCommentErrors(state) {
    return state.commentErrors;
  },
  getCommentNonFieldErrors(state) {
    return getNonFieldErrors(state.commentErrors);
  },
  isCommentLoading(state) {
    return state.commentLoading;
  }
};

const mutations = {
  setComments(state, { comments, serverLen = null }) {
    state.comments = [...comments];
    state.commentsServerLen = serverLen || comments.length;
  },
  appendComments(state, { comments, serverLen = null }) {
    state.comments = [...state.comments, ...comments];
    state.commentsServerLen = serverLen || comments.length;
  },
  updateComments(state, comment) {
    const oldLen = state.comments.length;
    state.comments = assignIf(state.comments, c => c.id === comment.id, comment);
    state.commentsServerLen += state.comments.length - oldLen;
  },
  deleteComments(state, comment) {
    const oldLen = state.comments.length;
    state.comments = deleteIf(state.comments, c => c.id === comment.id);
    state.commentsServerLen += state.comments.length - oldLen;
  },
  setCommentsLoading(state, loading) {
    state.commentsLoading = loading;
  },
  setComment(state, comment) {
    state.comment = { ...comment };
    state.commentErrors = {};
  },
  setCommentProp(state, { prop, value }) {
    state.comment = { ...state.comment, [prop]: value };
    state.commentErrors = { ...state.commentErrors, [prop]: null };
  },
  setCommentErrors(state, errors) {
    state.commentErrors = { ...errors };
  },
  setCommentLoading(state, loading) {
    state.commentLoading = loading;
  }
};

const actions = {
  async fetchComments({ commit }, { tourId, params = {}, append = false } = {}) {
    try {
      commit('setCommentsLoading', true);
      const rest = new Rest();
      const url = new Url(COMMENTS_URL, { tourId, id: null });
      const response = await rest.get(url.toString(), params);
      const mutator = append ? 'appendComments' : 'setComments';
      commit(mutator, {
        comments: response.data.results,
        serverLen: response.data.count
      });
      return true;
    } catch (err) {
      commit('setComments', { comments: [], serverLen: 0 });
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setCommentsLoading', false);
    }
  },
  async fetchComment({ commit }, { tourId, id, params = {} }) {
    try {
      commit('setCommentLoading', true);
      const rest = new Rest();
      const url = new Url(COMMENTS_URL, { tourId, id });
      const response = await rest.get(url.toString(), params);
      commit('setComment', response.data);
      return true;
    } catch (err) {
      commit('setComment', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setCommentLoading', false);
    }
  },
  async saveComment({ state, getters, commit }) {
    try {
      commit('setCommentLoading', true);
      const rest = new Rest();
      const url = new Url(COMMENTS_URL, { tourId: state.comment.tour, id: state.comment.id });
      const save = state.comment.id ? rest.patch : rest.post;
      const response = await save.call(rest, url.toString(), state.comment);
      commit('setComment', response.data);
      commit('updateComments', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setCommentErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getCommentNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setCommentLoading', false);
    }
  },
  async deleteComment({ commit }, { comment }) {
    try {
      commit('setCommentLoading', true);
      const rest = new Rest();
      const url = new Url(COMMENTS_URL, { tourId: comment.tour, id: comment.id });
      await rest.delete(url.toString());
      commit('deleteComments', comment);
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.deleteErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setCommentLoading', false);
    }
  },
  async markCommentsAsSeen(_, { tourId }) {
    try {
      const rest = new Rest();
      const url = new Url(SEEN_COMMENTS_URL, { tourId });
      await rest.post(url.toString());
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
