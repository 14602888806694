import users from '@/users/store/users';
import profile from '@/users/store/profile';
import profilePassword from '@/users/store/profile-password';

export default {
  namespaced: true,
  modules: {
    users,
    profile,
    profilePassword
  }
};
