const state = {
  message: '',
  opened: false,
  color: 'success',
  blocked: false
};

const getters = {
  getMessage(state) {
    return state.message;
  },
  isOpened(state) {
    return state.opened;
  },
  getColor(state) {
    return state.color;
  }
};

const mutations = {
  showMessage(state, { message, color = 'success', block = false }) {
    if (!state.blocked) {
      state.message = message;
      state.opened = true;
      state.color = color;
      state.blocked = block;
    }
  },
  hideMessage(state) {
    state.message = '';
    state.opened = false;
    state.blocked = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
