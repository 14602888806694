import axios from 'axios';
import qs from 'qs';

import store from '@/tog/store';
import router from '@/tog/router';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:8080/';

class Http {
  static contentType = 'application/json';
  static acceptLanguage = 'pl,en';

  static cancelAll = null;
  static cancelToken = createCancelToken();

  constructor({ config = { headers: {} }, auth = true } = {}) {
    config.headers['Content-Type'] = config.headers['Content-Type'] || Http.contentType;
    config.headers['Accept-Language'] = config.headers['Accept-Language'] || Http.acceptLanguage;
    if (auth && isAuth()) {
      config.headers['Authorization'] = getAuthHeader();
    }
    this.http = axios.create(config);
    if (auth) {
      this.http.interceptors.response.use(res => res, refreshAuthToken);
    }
  }

  fetch(method, url, config) {
    return this.http.request({
      method,
      url,
      cancelToken: Http.cancelToken,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      ...config
    });
  }
}

function createCancelToken() {
  return new axios.CancelToken(fn => {
    Http.cancelAll = () => {
      fn();
      Http.cancelToken = createCancelToken();
    };
  });
}

function isAuth() {
  return store.getters['auth/isAuth'];
}

function getAuthHeader() {
  return `Bearer ${store.getters['auth/getAccessToken']}`;
}

async function refreshAuthToken(err) {
  if (err?.response?.status === 401 && err?.response?.data?.code === 'token_not_valid') {
    if (await store.dispatch('auth/refresh')) {
      const config = { ...err.response.config };
      config.headers.Authorization = getAuthHeader();
      return axios(config);
    } else {
      router.push({ name: 'auth:login' }).catch(() => {});
    }
  }
  throw err;
}

export default Http;
