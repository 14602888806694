import Vue from 'vue';
import VueI18n from 'vue-i18n';
import vuetifyPl from 'vuetify/es5/locale/pl';

import pl from '@/tog/locale/pl';

Vue.use(VueI18n);

const messages = {
  pl: {
    $t: { ...pl },
    $vuetify: { ...vuetifyPl }
  }
};

const pluralizationRules = {
  'pl': (choice) => {
    switch (choice) {
    case 1:
      return 1;
    case 2:
    case 3:
    case 4:
      return 2;
    default:
      return 3;
    }
  }
};

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  preserveDirectiveContent: true,
  messages,
  pluralizationRules
});
