import store from '@/tog/store';

export function isAuthorized(to, from, next) {
  return store.getters['auth/isAuth'] ? next() : next({ name: 'auth:login' });
}

export function isUnauthorized(to, from, next) {
  return store.getters['auth/isAuth'] ? next({ name: 'home' }) : next();
}

export function redirectToHome() {
  if (store.getters['auth/isSuperUser'] || store.getters['auth/isStaff']) {
    return { name: 'tours:list' };
  } else if (store.getters['auth/isCustomer']) {
    return { name: 'destination:select' };
  } else {
    return { name: 'destination:select' };
  }
}
