<template>
  <v-menu
    v-model="menu"
    offset-y
    min-width="360"
    rounded="b-lg"
    :close-on-content-click="true"
  >
    <template #activator="menuActivator">
      <v-tooltip bottom>
        <template #activator="tooltipActivator">
          <v-btn
            v-on="{ ...menuActivator.on, ...tooltipActivator.on }"
            v-bind="{ ...menuActivator.attrs, ...tooltipActivator.attrs }"
            icon
          ><v-icon color="secondary">$mdiCog</v-icon></v-btn>
        </template>
        <span v-t="'$t.systemManagement'"></span>
      </v-tooltip>
    </template>

    <v-card>
      <v-list>
        <v-list-item
          color="primary"
          exact
          :to="{ name: 'tours:destinations:list' }"
        >
          <v-list-item-icon>
            <v-icon>$mdiEarth</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-t="'$t.destinations'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          color="primary"
          exact
          :to="{ name: 'tours:list' }"
        >
          <v-list-item-icon>
            <v-icon>$mdiChatQuestion</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-t="'$t.requests'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="isSuperUser">
          <v-divider></v-divider>

          <v-list-item
            color="primary"
            exact
            :to="{ name: 'users:list' }"
          >
            <v-list-item-icon>
              <v-icon>$mdiAccountGroupOutline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-t="'$t.users'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            color="primary"
            exact
            :to="{ name: 'legal-files:list' }"
          >
            <v-list-item-icon>
              <v-icon>$mdiFileDocumentOutline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-t="'$t.files'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      menu: false
    };
  },
  computed: {
    ...mapGetters({
      isSuperUser: 'auth/isSuperUser'
    })
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    async signout() {
      if (await this.logout()) {
        this.$router.push({ name: 'auth:login' });
      }
    }
  }
};
</script>
