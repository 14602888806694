import i18n from '@/plugins/i18n';
import Rest from '@/tog/services/rest';
import Url from '@/tog/services/url';
import ErrorHandler from '@/tog/services/error-handler';
import { assignIf, deleteIf, getNonFieldErrors } from '@/tog/store/utils';

const DESTINATIONS_URL = '/api/v1/tours/destinations/:id/';
const DESTINATIONS_ARRAY_URL = '/api/v1/tours/destinations/array/';

const state = {
  destinations: [],
  destinationsServerLen: 0,
  destinationsArray: [],
  destinationsLoading: false,
  destinationsArrayLoading: false,
  destination: {},
  destinationErrors: {},
  destinationLoading: false
};

const getters = {
  getDestinations(state) {
    return state.destinations;
  },
  getDestinationsServerLen(state) {
    return state.destinationsServerLen;
  },
  getDestinationsArray(state) {
    return state.destinationsArray;
  },
  isDestinationsLoading(state) {
    return state.destinationsLoading;
  },
  isDestinationsArrayLoading(state) {
    return state.destinationsArrayLoading;
  },
  getDestination(state) {
    return state.destination;
  },
  getDestinationErrors(state) {
    return state.destinationErrors;
  },
  getDestinationNonFieldErrors(state) {
    return getNonFieldErrors(state.destinationErrors);
  },
  isDestinationLoading(state) {
    return state.destinationLoading;
  }
};

const mutations = {
  setDestinations(state, { destinations, serverLen = null }) {
    state.destinations = [...destinations];
    state.destinationsServerLen = serverLen || destinations.length;
  },
  appendDestinations(state, { destinations, serverLen = null }) {
    state.destinations = [...state.destinations, ...destinations];
    state.destinationsServerLen = serverLen || destinations.length;
  },
  updateDestinations(state, destination) {
    const oldLen = state.destinations.length;
    state.destinations = assignIf(state.destinations, d => d.id === destination.id, destination);
    state.destinationsServerLen += state.destinations.length - oldLen;
  },
  deleteDestinations(state, destination) {
    const oldLen = state.destinations.length;
    state.destinations = deleteIf(state.destinations, d => d.id === destination.id);
    state.destinationsServerLen += state.destinations.length - oldLen;
  },
  setDestinationsArray(state, destinations) {
    state.destinationsArray = [...destinations];
  },
  setDestinationsLoading(state, loading) {
    state.destinationsLoading = loading;
  },
  setDestinationsArrayLoading(state, loading) {
    state.destinationsArrayLoading = loading;
  },
  setDestination(state, destination) {
    state.destination = { ...destination };
    state.destinationErrors = {};
  },
  setDestinationProp(state, { prop, value }) {
    state.destination = { ...state.destination, [prop]: value };
    state.destinationErrors = { ...state.destinationErrors, [prop]: null };
  },
  setDestinationErrors(state, errors) {
    state.destinationErrors = { ...errors };
  },
  setDestinationLoading(state, loading) {
    state.destinationLoading = loading;
  }
};

const actions = {
  async fetchDestinations({ commit }, { params = {}, append = false } = {}) {
    try {
      commit('setDestinationsLoading', true);
      const rest = new Rest();
      const url = new Url(DESTINATIONS_URL, { id: null });
      const response = await rest.get(url.toString(), params);
      const mutator = append ? 'appendDestinations' : 'setDestinations';
      commit(mutator, {
        destinations: response.data.results,
        serverLen: response.data.count
      });
      return true;
    } catch (err) {
      commit('setDestinations', { destinations: [], serverLen: 0 });
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setDestinationsLoading', false);
    }
  },
  async fetchDestinationsArray({ commit }, { params = {} } = {}) {
    try {
      commit('setDestinationsArrayLoading', true);
      const rest = new Rest();
      const response = await rest.get(DESTINATIONS_ARRAY_URL, params);
      commit('setDestinationsArray', response.data);
      return true;
    } catch (err) {
      commit('setDestinationsArray', []);
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setDestinationsArrayLoading', false);
    }
  },
  async fetchDestination({ commit }, { id, params = {} }) {
    try {
      commit('setDestinationLoading', true);
      const rest = new Rest();
      const url = new Url(DESTINATIONS_URL, { id });
      const response = await rest.get(url.toString(), params);
      commit('setDestination', response.data);
      return true;
    } catch (err) {
      commit('setDestination', {});
      const errorHandler = new ErrorHandler(err);
      errorHandler.handle();
      return false;
    } finally {
      commit('setDestinationLoading', false);
    }
  },
  async saveDestination({ state, getters, commit }) {
    try {
      commit('setDestinationLoading', true);
      const rest = new Rest();
      const url = new Url(DESTINATIONS_URL, { id: state.destination.id });
      const save = state.destination.id ? rest.patch : rest.post;
      let response = {};
      if (state.destination.image instanceof Blob) {
        const form = new FormData();
        for (const prop in state.destination) {
          form.append(prop, state.destination[prop]);
        }
        response = await save.call(
          rest, url.toString(), form, { headers: { 'Content-Type': 'multipart/form' } });
      } else if (state.destination.image === null) {
        response = await save.call(rest, url.toString(), state.destination);
      } else {
        // eslint-disable-next-line no-unused-vars
        const { image, ...data } = state.destination;
        response = await save.call(rest, url.toString(), data);
      }
      commit('setDestination', response.data);
      commit('updateDestinations', response.data);
      commit(
        'snackbar/showMessage',
        { message: i18n.t('$t.savedDot') },
        { root: true });
      return true;
    } catch (err) {
      commit('setDestinationErrors', err.response.data);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getDestinationNonFieldErrors);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.saveErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setDestinationLoading', false);
    }
  },
  async deleteDestination({ commit }, destination) {
    try {
      commit('setDestinationLoading', true);
      const rest = new Rest();
      const url = new Url(DESTINATIONS_URL, { id: destination.id });
      await rest.delete(url.toString());
      commit('deleteDestinations', destination);
      return true;
    } catch (err) {
      const errorHandler = new ErrorHandler(err);
      errorHandler.updateRestStatusCodeErrors({ 400: i18n.t('$t.deleteErrorDot') });
      errorHandler.handle();
      return false;
    } finally {
      commit('setDestinationLoading', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
