import { isUnauthorized } from '@/tog/router/utils';

export default [{
  path: '/login',
  name: 'auth:login',
  beforeEnter: isUnauthorized,
  component: () => import(/* webpackChunkName: 'auth' */ '@/auth/views/login-view')
}, {
  path: '/login/google',
  name: 'auth:login-google',
  beforeEnter: isUnauthorized,
  props: route => ({ ...route.query }),
  component: () => import(/* webpackChunkName: 'auth' */ '@/auth/views/login-google-view')
}, {
  path: '/login/facebook',
  name: 'auth:login-facebook',
  beforeEnter: isUnauthorized,
  props: route => ({ ...route.query }),
  component: () => import(/* webpackChunkName: 'auth' */ '@/auth/views/login-facebook-view')
}, {
  path: '/registration',
  name: 'auth:register',
  beforeEnter: isUnauthorized,
  component: () => import(/* webpackChunkName: 'auth' */ '@/auth/views/registration-view')
}, {
  path: '/registration/confirmation/:token/:userId',
  name: 'auth:register-confirmation',
  beforeEnter: isUnauthorized,
  props: true,
  component: () => import(
    /* webpackChunkName: "auth" */ '@/auth/views/registration-confirmation-view.vue')
}, {
  path: '/password/retrieval',
  name: 'auth:password-retrieval:request',
  beforeEnter: isUnauthorized,
  props: true,
  component: () => import(
    /* webpackChunkName: "auth" */ '@/auth/views/password-retrieval-request.vue')
}, {
  path: '/password/retrieval/:token/:id',
  name: 'auth:password-retrieval:update',
  beforeEnter: isUnauthorized,
  props: true,
  component: () => import(
    /* webpackChunkName: "auth" */ '@/auth/views/password-retrieval-update.vue')
}];
