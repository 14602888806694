import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import i18n from '@/plugins/i18n';

import {
  mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiChevronDown, mdiAccount, mdiAccountOutline,
  mdiLogout, mdiMapMarker, mdiPhone, mdiEmail, mdiChatQuestion, mdiEye, mdiEyeOff, mdiPencil,
  mdiDelete, mdiCog, mdiEarth, mdiPlus, mdiRefresh, mdiImageMultiple, mdiNumeric1Box, mdiAlphaNBox,
  mdiClose, mdiFullscreen, mdiFullscreenExit, mdiArchiveArrowDown, mdiArchiveArrowUp, mdiChat,
  mdiContentCopy, mdiShareVariant, mdiAccountGroupOutline, mdiLockReset, mdiFilter, mdiGoogle,
  mdiFacebook, mdiFileDocumentOutline, mdiArrowLeft, mdiHome, mdiAirplaneAlert
} from '@mdi/js';

import togSave from '@/assets/save-icon.vue';
import togCheck from '@/assets/check-icon.vue';
import togForm from '@/assets/form-icon.vue';
import togFlag from '@/assets/flag-icon.vue';
import togContact from '@/assets/contact-icon.vue';
import togSummary from '@/assets/summary-icon.vue';
import togHistory from '@/assets/history-icon.vue';
import togNature from '@/assets/nature-icon.vue';
import togPay from '@/assets/pay-icon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#ef5023',
        secondary: '#2bc6c8'
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      mdiChevronLeft,
      mdiChevronRight,
      mdiChevronUp,
      mdiChevronDown,
      mdiAccount,
      mdiAccountOutline,
      mdiLogout,
      mdiMapMarker,
      mdiPhone,
      mdiEmail,
      mdiChatQuestion,
      mdiEye,
      mdiEyeOff,
      mdiPencil,
      mdiDelete,
      mdiCog,
      mdiEarth,
      mdiPlus,
      mdiRefresh,
      mdiImageMultiple,
      mdiNumeric1Box,
      mdiAlphaNBox,
      mdiClose,
      mdiFullscreen,
      mdiFullscreenExit,
      mdiArchiveArrowDown,
      mdiArchiveArrowUp,
      mdiChat,
      mdiContentCopy,
      mdiShareVariant,
      mdiAccountGroupOutline,
      mdiLockReset,
      mdiFilter,
      mdiGoogle,
      mdiFacebook,
      mdiFileDocumentOutline,
      mdiArrowLeft,
      mdiHome,
      mdiAirplaneAlert,
      togSave: { component: togSave },
      togCheck: { component: togCheck },
      togForm: { component: togForm },
      togFlag: { component: togFlag },
      togContact: { component: togContact },
      togSummary: { component: togSummary },
      togHistory: { component: togHistory },
      togNature: { component: togNature },
      togPay: { component: togPay }
    }
  }
});
