import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFound from '@/tog/views/404';
import { isAuthorized, redirectToHome } from '@/tog/router/utils';

import auth from '@/auth/router';
import users from '@/users/router';
import tours from '@/tours/router';
import docs from '@/docs/router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: isAuthorized,
    redirect: redirectToHome
  },
  ...auth,
  ...users,
  ...tours,
  ...docs,
  {
    path: '/404/',
    name: 'not-found',
    component: NotFound,
  }, {
    path: '*',
    redirect: { name: 'not-found' }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
