import i18n from '@/plugins/i18n';
import Http from '@/tog/services/http';
import ErrorHandler from '@/tog/services/error-handler';
import { loadData, saveData, getNonFieldErrors } from '@/tog/store/utils';

const LOGIN_URL = '/api/v1/auth/token/';
const REFRESH_URL = '/api/v1/auth/token/refresh/';
const LOGIN_GOOGLE_URL = '/api/v1/auth/social-accounts/google/';
const LOGIN_FACEBOOK_URL = '/api/v1/auth/social-accounts/facebook/';

const state = {
  isAuthenticated: loadData('auth/isAuthenticated', false),
  accessToken: loadData('auth/accessToken'),
  refreshToken: loadData('auth/refreshToken'),
  errors: {},
  googleLoginState: loadData('auth/googleLoginState', null),
  facebookLoginState: loadData('auth/facebookLoginState', null)
};

const getters = {
  isAuth(state) {
    return state.isAuthenticated;
  },
  getAccessToken(state) {
    return state.accessToken;
  },
  getRefreshToken(state) {
    return state.refreshToken;
  },
  getErrors(state) {
    return state.errors;
  },
  getNonFieldErrors(state) {
    return getNonFieldErrors(state.errors);
  },
  getGoogleLoginState(state) {
    return state.googleLoginState;
  },
  getFacebookLoginState(state) {
    return state.facebookLoginState;
  }
};

const mutations = {
  setAuth(state, authenticated) {
    state.isAuthenticated = authenticated;
    saveData('auth/isAuthenticated', state.isAuthenticated);
  },
  setAccessToken(state, token) {
    state.accessToken = token;
    saveData('auth/accessToken', state.accessToken);
  },
  setRefreshToken(state, token) {
    state.refreshToken = token;
    saveData('auth/refreshToken', state.refreshToken);
  },
  setErrors(state, errors) {
    state.errors = { ...errors };
  },
  setGoogleLoginState(state) {
    state.googleLoginState = Math.random().toString().substr(2, 10);
    saveData('auth/googleLoginState', state.googleLoginState);
  },
  setFacebookLoginState(state) {
    state.facebookLoginState = Math.random().toString().substr(2, 10);
    saveData('auth/facebookLoginState', state.facebookLoginState);
  }
};

const actions = {
  async login({ getters, commit }, { email, password }) {
    try {
      const http = new Http({ auth: false });
      const response = await http.fetch('post', LOGIN_URL, { data: { email, password } });
      commit('setAuth', true);
      commit('setAccessToken', response.data.access);
      commit('setRefreshToken', response.data.refresh);
      commit('setUserType', response.data.user?.type);
      return true;
    } catch (err) {
      commit('setErrors', err.response.data);
      commit('setAuth', false);
      commit('setAccessToken', null);
      commit('setRefreshToken', null);
      commit('setUserType', null);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getNonFieldErrors);
      errorHandler.handle();
      return false;
    }
  },
  async refresh({ commit }) {
    try {
      const http = new Http({ auth: false });
      const response = await http.fetch('post', REFRESH_URL, {
        data: { refresh: state.refreshToken }
      });
      commit('setAccessToken', response.data.access);
      return true;
    } catch (err) {
      Http.cancelAll('refresh');
      commit('setErrors', err.response.data);
      commit('setAuth', false);
      commit('setAccessToken', null);
      commit('setRefreshToken', null);
      commit('setUserType', null);
      const errorHandler = new ErrorHandler(err);
      errorHandler.updateRestStatusCodeErrors({
        401: i18n.t('$t.sessionExpiredDot')
      });
      errorHandler.setBlockMode(true);
      errorHandler.setColor('warning');
      errorHandler.handle();
      return false;
    }
  },
  logout({ commit }) {
    Http.cancelAll('logout');
    commit('setAuth', false);
    commit('setAccessToken', null);
    commit('setRefreshToken', null);
    commit('setUserType', null);
    return true;
  },
  async loginGoogle({ getters, commit }, { code }) {
    try {
      const http = new Http({ auth: false });
      const response = await http.fetch('post', LOGIN_GOOGLE_URL, {
        data: { code }
      });
      commit('setAuth', true);
      commit('setAccessToken', response.data.access_token);
      commit('setRefreshToken', response.data.refresh_token);
      commit('setUserType', response.data.user?.type);
      return true;
    } catch (err) {
      commit('setErrors', err.response.data);
      commit('setAuth', false);
      commit('setAccessToken', null);
      commit('setRefreshToken', null);
      commit('setUserType', null);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getNonFieldErrors);
      errorHandler.handle();
      return false;
    }
  },
  async loginFacebook({ getters, commit }, { code }) {
    try {
      const http = new Http({ auth: false });
      const response = await http.fetch('post', LOGIN_FACEBOOK_URL, {
        data: { code }
      });
      commit('setAuth', true);
      commit('setAccessToken', response.data.access_token);
      commit('setRefreshToken', response.data.refresh_token);
      commit('setUserType', response.data.user?.type);
      return true;
    } catch (err) {
      commit('setErrors', err.response.data);
      commit('setAuth', false);
      commit('setAccessToken', null);
      commit('setRefreshToken', null);
      commit('setUserType', null);
      const errorHandler = new ErrorHandler(err);
      errorHandler.setSuffix(getters.getNonFieldErrors);
      errorHandler.handle();
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
